<template>
    <div class="w-full h-full flex flex-col">
        <div 
            v-if="products"
            class="
                flex-none 
                flex 
                items-center
                flex-col
                
                md:h-32
                md:py-8
                md:px-16
                md:flex-row
            ">
            
            <div 
                class="
                    flex items-center justify-center
                    h-12 
                    w-full
                    bg-clay
                    md:bg-transparent
                    
                    md:h-auto
                    md:w-auto
                ">
                
                <h1 class="text-white md:text-black">
                    {{$t('products.products')}}
                </h1>
            </div>
            
            <div class="w-full flex items-center px-4 md:px-4 h-20">
                <div class="flex-grow flex items-center">
                    <div 
                        class="
                            mr-4 relative
                            w-full
                            md:w-64
                        ">
                        
                        <input 
                            class="w-full flex-none rounded-full px-8 py-3 outline-none "
                            :placeholder="$t('products.searchProducts')"
                            v-model="searchString"
                        />
                        
                        <i 
                            v-if="!searchString" 
                            class="fas fa-search text-lg text-gray-400 absolute right-0 top-0 mt-4 mr-4 pointer-events-none" 
                        />
                        <i 
                            v-else
                            class="fas fa-times text-lg text-gray-400 absolute right-0 top-0 mt-4 mr-5" 
                            @click="searchString = ''"
                        />
                    </div>
                    <h2 
                        v-if="searchString"
                        class="opacity-50 ml-8 hidden md:block">
                        
                        {{productsFiltered.length}} {{ $t('general.hitsOnSearch') }}
                    </h2>
                </div>
                <div class="flex-none flex">
                    <div 
                        class="fas fa-redo-alt block flex items-center justify-center bg-white text-gray-400 w-10 h-10 mr-4 rounded-full cursor-pointer refresh"
                        :class="{'loading': refreshing}"
                        @click="refresh()"
                    />
                    
                    <button 
                        @click="showProductNew = true"
                        class="bg-cyan text-white w-10 h-10 rounded-full">
                        
                        <i class="fas fa-plus text-lg" />
                    </button>
                </div>
            </div>
        </div>
        
        <div 
            class="
                w-full h-full flex-grow overflow-y-scroll 
                p-4
                pt-0
                md:px-16 
                md:pb-16
            ">
            <div 
                v-if="!products"
                class="fixed inset-0 flex items-center justify-center">
                
                <div class="spinner"></div>
            </div>
            
            <transition-group 
                appear
                name="slide-up" 
                tag="div"
                class="
                    grid 
                    grid-cols-1 
                    lg:grid-cols-2 
                    xl:grid-cols-3 
                    gap-4
                    md:gap-16
                "
                v-else-if="products && productsFiltered.length > 0">
                
                <!-- class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-16" -->
                
                <ProductCard 
                    v-for="(product) in productsFiltered"
                    :key="product.productId"
                    :product="product"
                    @edit="productEdit = product"
                    class="slide-up-item"
                />
                <!-- class="w-full sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/4 " -->
            </transition-group>
            
            <transition 
                v-else
                name="pop"
                appear>
                
                <div class="h-full w-full flex items-center justify-center pointer-events-none">
                    <div class="bg-white rounded-full p-12 text-center w-100 h-100">
                        <img 
                            src="/assets/illustrations/undraw_gone_shopping_vwmc.svg" 
                            class="object-contain mb-8 w-48 h-48 mx-auto"
                            alt="No records illustrations"
                        />
                        <button 
                            class="button submit mx-auto pointer-events-auto"
                            @click="showProductNew = true">
                            
                            {{$t('products.createProductNow')}}
                        </button>
                    </div>
                </div>
            </transition>
            
        </div>
        
        <div 
            v-if="productEdit"
            class="
                absolute inset-0 flex
                mb-16 
                md:mb-0
            ">
            
            <div 
                @click="productEdit = null"
                class="flex-grow bg-black opacity-25 ">
                
            </div>
            
            <transition name="fade">
                <ProductEdit 
                    :product="productEdit" 
                    @close="productEdit = null"
                    class="
                        absolute bg-white right-0 overflow-hidden w-full
                        md:rounded-l-lg 
                        md:w-150
                    "
                />
            </transition>
        </div>
        
        <Modal 
            v-if="showProductNew"
            @close="showProductNew = false"
            insideClasses="bg-white">
            
            <header slot="header">
                <div class="py-4 px-6">
                    {{$t('products.createProductNow')}}
                </div>
            </header>
            
            <form 
                id="products-form"
                @submit.prevent="createProduct()"
                class="pb-2 px-6">
                
                <InputSingleline 
                    v-model="productNew.name"
                    :label="$t('products.name')"
                    name="productName"
                    :placeholder="$t('products.name')"
                />
            </form>
            
            <footer slot="footer">
                <div class="buttons-wrapper">
                    <a 
                        class="button transparent link" 
                        @click="showProductNew = false">
                        
                        {{$t('general.cancel')}}
                    </a>
                    
                    <button 
                        :disabled="productCreating"
                        type="submit"
                        class="button submit"
                        :class="{'loading' : productCreating}"
                        @click.prevent="createProduct()">
                        
                        {{$t('general.create')}}
                    </button>
                </div>
            </footer>
        </Modal>
        
    </div>
</template>

<script>
    export default {
        components: {
            'ProductCard': () => import('@/components/products/ProductCard.vue'),
            'ProductEdit': () => import('@/components/products/ProductEdit.vue'),
        },
        
        computed:{
            products(){
                // if ( excludeDeleted ) {
                //     response.data.Items = response.data.Items.filter( c => !c.deletedAt);
                // }
                return this.$store.getters.getProducts;
            },
            
            productsFiltered(){
                if (!this.searchString) {
                    return this.products.filter( p => !p.deletedAt );
                }
                
                return this.products.filter( p => {
                    let itemValues = [
                        p.productId,
                    ];
                    
                    // flatten all values into a CSV string
                    for ( const k in p ){
                        if ( 
                            !k.endsWith('Id') && 
                            typeof p[k] != 'object' ) {
                            itemValues.push( p[k] );
                        }
                    }
                    
                    const matchString = itemValues.join(';').toLowerCase();
                    
                    if ( matchString.includes( this.searchString.toLowerCase().trim() )) {
                        return p;
                    }
                });
            }
        },
        
        data(){
            return {
                showProductNew: false,
                loading: true,
                refreshing: false,
                productEdit: null,
                productNew: {},
                productCreating: false,
                searchString: '',
            }
        },
        
        methods: {
            async createProduct(){
                try {
                    if (!this.productNew.name) {
                        throw Error( this.$t('products.needName') )
                    }
                    
                    this.productCreating = true;
                    const product = await this.$store.dispatch('createProduct', this.productNew);
                    this.productNew = {};
                    this.showProductNew = false;
                    this.productEdit = product;
                } 
                catch (e) {
                    this.$notify({ 
                        type: 'error', 
                        // title: 'Error', 
                        text: e.message || this.$t('products.problemCreating')
                    });
                    
                    console.error(e);
                } 
                finally {
                    this.productCreating = false;
                }
            },
            
            async refresh(){
                this.refreshing = true;
                await this.$store.dispatch('listProducts');
                this.refreshing = false;
                
                analytics.track( 'refreshOffers');
            },
        },
        
        async created(){
            this.loading = false;
        }
    }
</script>

<style lang="scss" >

</style>
